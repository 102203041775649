import firebase from "firebase";
import 'firebase/firestore';

const init = () => {
    var firebaseConfig = {
        apiKey: "AIzaSyCAfOiOHXTe_pRaygOR8losjG6sYy3InoQ",
        authDomain: "stapto-home.firebaseapp.com",
        databaseURL: "https://stapto-home.firebaseio.com",
        projectId: "stapto-home",
        storageBucket: "stapto-home.appspot.com",
        messagingSenderId: "273699376525",
        appId: "1:273699376525:web:573c2be6e2cf3ff302c1c4",
        measurementId: "G-WYHTY0BB99"
    };

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    firebase.analytics().logEvent("page_view");
}

const createNewDocument = (collectionName, data) => {
    return firebase.firestore().collection(collectionName).add(data);
}

const updateDocument = (collectionName, docName, data) => {
    return firebase.firestore().collection(collectionName).doc(docName).set(data);
}

const getCollection = (collectionName) => {

    return new Promise(async (resolve) => {
        let items = [];

        try {
            let collection = await firebase.firestore().collection(collectionName).get();
        
            collection.forEach(async (doc) => {
                const docItem = doc.data();
                const docItemId = doc.id;
                
                items.push({
                    ...docItem,
                    docId: docItemId
                });
            });
            
        } catch(err) {}

        resolve(items);
    });
}

export default {
    init,
    getCollection,
    createNewDocument,
    updateDocument
}