import React from 'react';
import {
    Collapse,
    IconButton,
} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import Alert from '@material-ui/lab/Alert';

const SuccessMessage = ({ open, setOpen }) => {
    return (
        <Collapse in={open}>
            <Alert
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                Thank you for contacting.
            </Alert>
        </Collapse>
    );
};

export default SuccessMessage;
