import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Hidden,
} from '@material-ui/core';
import firebase from "../../utils/firebase";
import SuccessMessage from "../SuccessMessage";
import LoadingSpinner from '../LoadingSpinner';
import constants from 'src/config/constants';

const fields = {
  name: '',
  email: '',
  phone: '',
  message: ''
};

const ContactForm = ({ ...rest }) => {
  const [values, setValues] = useState(fields);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(constants.reCaptchaKey, {action: 'submit'}).then(async (token) => {
        setIsLoading(true);
        await firebase.createNewDocument("messages", {...values, createdAt: new Date()});
        setIsLoading(false);
        setOpen(true);
        setValues(fields);
      });
    });

  }

  return (
    <form
      autoComplete="off"
      // noValidate
      {...rest}
      onSubmit={onSubmit}
    >
      <LoadingSpinner isLoading={isLoading} />
      <Card>
        <CardHeader title="Leave a message" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SuccessMessage open={open} setOpen={setOpen} />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                size="small"
                onChange={handleChange}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Hidden xsDown>
              <Grid item md={6} xs={12}></Grid>
            </Hidden>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                size="small"
                onChange={handleChange}
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                size="small"
                onChange={handleChange}
                type="number"
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} >
              <TextField
                fullWidth
                label="Message"
                size="small"
                placeholder="Start writing your message..."
                name="message"
                onChange={handleChange}
                required
                value={values.message}
                variant="outlined"
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button type="submit" color="primary" variant="contained">
            Submit
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ContactForm.propTypes = {
  className: PropTypes.string
};

export default ContactForm;
