import React from 'react';
import {
  Grid,
  Divider,
  Typography,
  Box,
  Hidden
} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import ArunInfo from './ArunInfo';
import SocialLinks from './SocialLinks';
import FlexRow from '../FlexRow';

const About = ({ width }) => {

    return (
        <>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} id="about">
                <Typography variant="h1" color="textSecondary">
                    ABOUT
                </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <Typography gutterBottom align="center">
                    Stapto works on design, architecture and development of various applications, softwares and integrations.
                </Typography>
                <Box mb={4} />
                <Typography align="center">
                    A digital portfolio of Free Applications.
                </Typography>

                <SocialLinks />
            </Grid>
            
            {/* show divider if device is small */}
            <Hidden mdUp>
                <Grid item sm={4}></Grid>
                <Grid item sm={4}>
                    <Divider />
                </Grid>
                <Grid item sm={4}></Grid>
            </Hidden>
            <Hidden mdDown>
                <Grid item md={3} sm={12}>
                    <FlexRow justifyContent="center" alignItems="center">
                        <img width="200" src="static/images/illustrations/undraw_apps_m7mh.svg" />
                    </FlexRow>
                </Grid>
            </Hidden>
            {/* END - show divider if device is small */}

            <ArunInfo width={width} />
        </>
    );
};

export default withWidth()(About);
