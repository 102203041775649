import React from 'react';
import {
  Container,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Slider from 'src/components/Slider';
import Tagline from 'src/components/Tagline';
import ProductList from '../../components/Products/ProductList';
import About from '../../components/About/About';
import Contact from 'src/components/Contact/Contact';
import Footer from 'src/components/Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Grid container spacing={3} >
          
          <Slider />

          <Tagline />

          <ProductList />

          <About />

          <Contact />
          
          <Footer />
          
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
