import React from 'react';
import { 
    Grid,
    Card,
    Box,
    Typography,
    Hidden,
    withWidth
} from '@material-ui/core';
import { Favorite } from '@material-ui/icons';
import FlexRow from './FlexRow';
import { isSmOrXs } from 'src/utils';

const Footer = ({width}) => {
  return (
    <Grid item xs={12}>
        <Box mt={3}>
            <Card>
                <Box p={2}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Box display="flex" alignItems="center" justifyContent={isSmOrXs(width) ? "center" : "flex-start"}>
                                <Favorite color="error" />
                                <Box ml={1} />
                                <Typography>
                                    2015 Stapto. hello@stapto.com
                                </Typography>
                            </Box>
                        </Grid>
                        <Hidden smDown>
                            <Grid item md={6} xs={12}>
                                    <FlexRow justifyContent="flex-end">
                                        <img src="static/images/icon.png" width="20" />
                                    </FlexRow>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Box>
            </Card>
        </Box>
    </Grid>
  );
};

export default withWidth()(Footer);
