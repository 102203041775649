import React, {useState, useEffect} from 'react';
import {
  Grid,
  Divider,
  Typography
} from '@material-ui/core';
import ProductCard from "./ProductCard";
import firebase from "../../utils/firebase";
import { sortArrayOfObjects } from "../../utils";

const ProductList = ({ className, product, ...rest }) => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        getProducts();
    }, []);
    
    const getProducts = async () => {
        let items = await firebase.getCollection("products");
        items = sortArrayOfObjects(items, "order");
        setProducts(items);
    }

    return (
        <>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} id="products" >
                <Typography variant="h1" color="textSecondary">
                    PRODUCTS
                </Typography>
            </Grid>
            {products.map((product) => <Grid item lg={6} md={12} key={product.docId}>
                <ProductCard product={product} />
            </Grid>)}
        </>
    );
};

export default ProductList;
