import {
  Home,
  Category,
  Info,
  Chat
} from '@material-ui/icons';

export default [
    {
      id: 'home',
      href: '#home',
      icon: Home,
      title: 'Home'
    },
    {
      id: 'products',
      href: '#products',
      icon: Category,
      title: 'Products'
    },
    {
      id: 'about',
      href: '#about',
      icon: Info,
      title: 'About'
    },
    {
      id: 'contact',
      href: '#contact',
      icon: Chat,
      title: 'Contact'
    },
];