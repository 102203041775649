import constants from "src/config/constants";

export const sortArrayOfObjects = (array, sortKey, order = "ASC") => {
    function compare(a, b) {
        if (a[sortKey] < b[sortKey]) {
            return order === "ASC" ? -1 : 1;
        }
        if (a[sortKey] > b[sortKey]) {
            return order === "ASC" ? 1 : -1;
        }
        return 0;
    }
    
    return array.sort(compare);
}

export const scrollToDiv = (divId) => {
    let divRef = document.getElementById(divId);
    divRef.scrollIntoView({ behavior: 'smooth' });
}

export const isSmOrXs = (width) => ["xs", "sm"].indexOf(width) >= 0;

export const checkDomainAndRedirect = () => {
    // let host = window.location.host;

    // if (constants.firebaseHosts.indexOf(host) >= 0) {
    //     window.location = constants.homePage;
    // }
}