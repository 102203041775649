import React from 'react';
import {
  Box
} from '@material-ui/core';

const FlexRow = ({ children, ...rest }) => {
    return (
        <Box display="flex" flexDirection="row" alignItems="center" {...rest}>
            {children}
        </Box>
    );
};

export default FlexRow;
