import React from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  Avatar,
  Box,
  Button,
} from '@material-ui/core';
import { isSmOrXs } from 'src/utils';
import constants from 'src/config/constants';

const useStyles = makeStyles(() => ({
    avatar: {
        maxHeight: 150,
        maxWidth: 150,
        borderRadius: 5,
    },
    buyMeACoffee: {
        borderRadius: 10
    },
    container: {
        overflow: "hidden"
    }
}));

const ArunInfo = ({ width }) => {

    const classes = useStyles();

    return (
        <Grid item md={6} lg={3} sm={12} className={classes.container}>
            <Box p={2} display="flex" alignItems="center" justifyContent={isSmOrXs(width) ? "center" : "flex-end"}>
                <Box textAlign={isSmOrXs(width) ? "center" : "right"} mr={2}>
                    <Typography variant="h3" color="secondary" gutterBottom>
                        Arun D
                    </Typography>

                    <a href="https://ArunD.in" target="_blank">
                        <Typography variant="body1">
                            https://ArunD.in
                        </Typography>
                    </a>

                    <a href="email: a.nambissan@gmail.com" target="_blank">
                        <Typography variant="body1">
                            a.nambissan@gmail.com
                        </Typography>
                    </a>

                    <Box mb={2} />

                    <Button href={constants.buyMeACoffeePage} target="_blank">
                        <img className={classes.buyMeACoffee} src="static/images/buymeacoffee.png" height="40" />
                    </Button>
                </Box>
                <Box>
                    <img
                        src="static/images/arun_2.jpg"
                        className={classes.avatar}
                    />
                </Box>
            </Box>
        </Grid>
    );
};

export default ArunInfo;
