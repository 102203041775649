import React from 'react';
import {
  Grid,
  Box,
  Typography
} from '@material-ui/core';

const Tagline = ({ className, product, ...rest }) => {

    const illustrations = [
        "undraw_version_control_9bpv",
        "undraw_product_teardown_elol",
        "undraw_code_review_l1q9",
        "undraw_bug_fixing_oc7a",
        "undraw_web_devices_ad58",
        "undraw_web_developer_p3e5",
    ];

    return (
        <Grid item xs={12}>
            <Box p={5}>
                <Typography variant="h1" align="center" color="textSecondary" gutterBottom>
                    Turning ideas and needs into a bunch of code.
                </Typography>

                <Box mt={4} />

                <Grid container spacing={2} justify="center">
                    {illustrations.map(illustration => <Grid item md={2} key={illustration}>
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <img
                                alt="Stapto"
                                src={`static/images/illustrations/${illustration}.svg`}
                                width="150"
                            />
                        </Box>
                    </Grid>)}
                </Grid>
            </Box>
        </Grid>
    );
};

export default Tagline;
