import React from 'react';
import {
  Grid,
  Divider,
  Typography,
  Hidden
} from '@material-ui/core';
import ContactForm from './ContactForm';
import FlexRow from '../FlexRow';

const Contact = ({ className, product, ...rest }) => {
    return (
        <>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} id="contact">
                <Typography variant="h1" color="textSecondary">
                    CONTACT
                </Typography>
            </Grid>
            <Grid item lg={6} md={12}>
                <ContactForm />
            </Grid>
            <Hidden mdDown>
                <Grid item md={6}>
                    <FlexRow justifyContent="center" alignItems="center" height="100%">
                        <img src="static/images/illustrations/undraw_personal_email_t7nw.svg" width="300" />
                    </FlexRow>
                </Grid>
            </Hidden>
        </>
    );
};

export default Contact;
