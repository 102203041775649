import React, {useState} from 'react';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import useInterval from 'src/hooks/useInterval';

const useStyles = makeStyles((theme) => ({
    slider: {
        marginLeft: "-12px !important",
        marginTop: "-12px !important",
        marginRight: "-12px !important",
        width: "calc(100% + 48px)",
        [theme.breakpoints.down('sm')]: {
            width: "calc(100% + 40px)",
        }
    }
}));

const Slider = ({ className, product, ...rest }) => {
    const classes = useStyles();
    const [activeSlide, setActiveSlide] = useState(1);

    useInterval(() => {
        setActiveSlide(activeSlide === 3 ? 1 : activeSlide + 1)
    }, 4000);

    return (
        <Grid id="home" item xs={12} className={classes.slider}>
            {/* {slides.map(slide => <Slide key={slide} direction="left" in={activeSlide === slide} mountOnEnter unmountOnExit>
                <img className={classes.slider} src={`static/images/slider/slider${slide}.jpg`} />
            </Slide>)} */}
            <img className={classes.slider} src={`static/images/slider/slider${activeSlide}.jpg`} />
        </Grid>
    );
};

export default Slider;
