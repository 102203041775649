import React from 'react';
import { 
    Box,
    Typography,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import { scrollToDiv } from "../utils";

const useStyles = makeStyles((theme) => ({
    menuItem: {
        "&:hover": {
            borderRadius: "unset"
        }
    }
}));

const TopMenuItem = (props) => {
    const { item, item: {icon: Icon} } = props;
    const classes = useStyles();

    return (
        <IconButton color="inherit" className={classes.menuItem} onClick={() => scrollToDiv(item.id)}>
            <Icon />
            <Box ml={0.5} />
            <Typography>
                {item.title}
            </Typography>
        </IconButton>
    );
};

export default TopMenuItem;
