import React from 'react';
import {
  makeStyles,
  Tooltip,
  Button,
} from '@material-ui/core';
import constants from 'src/config/constants';
import FlexRow from '../FlexRow';
import {
    Instagram,
    Facebook,
    Shop
} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    icon: {
        fontSize: "55px",
    },
}));

const SocialLinks = ({ className, product, width }) => {

    const classes = useStyles();

    return (
        <FlexRow mt={4} width="100%" justifyContent="space-around">
            <Tooltip title="Facebook">
                <Button href={constants.facebookPage} target="_blank">
                    <Facebook color="primary" title="Facebook" className={classes.icon} />
                </Button>
            </Tooltip>
            <Tooltip title="Play Store">
                <Button href={constants.playStorePage} target="_blank">
                    <Shop color="action" title="Play Store" className={classes.icon} />
                </Button>
            </Tooltip>
            <Tooltip title="Instagram">
                <Button href={constants.instagramPage} target="_blank">
                    <Instagram color="secondary" title="Instagram" className={classes.icon} />
                </Button>
            </Tooltip>
        </FlexRow>
    );
};

export default SocialLinks;
