import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  CardHeader
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ProductCard = ({ className, product, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
        <CardHeader title={product.name} color="secondary" />
        <Divider />
        <CardContent>
            <Grid container spacing={2}>
              {product.thumbnail && 
                <Grid item md={3}>
                  <Box display="flex" justifyContent="center" width="100%">
                    <img width={product.thumbnail.width} src={product.thumbnail.url} />
                  </Box>
                </Grid>
              }
              <Grid item md={9}>
                <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                  <Typography>
                    {product.description}
                  </Typography>
                  {product.buttons &&
                    <Box mt={2}>
                      <Grid container spacing={2}>
                          {product.buttons.map(button => <Grid item key={button.title}>
                            <Button size="small" variant="outlined" color="primary" href={button.url} target="_blank">
                              {button.title}
                            </Button>
                          </Grid>)}
                      </Grid>
                    </Box>
                  }
                </Box>
              </Grid>
            </Grid>
        </CardContent>
    </Card>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCard;
